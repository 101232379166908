import cn from 'classnames';
import PropTypes from 'prop-types';

import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import CloseIcon from 'assets/icons/CrossIcon';
import ButtonPrimary from 'components/ButtonPrimary';
const Modal = ({
  isOpen,
  renderCancelButton = true,
  width = '860',
  title,
  children,
  closeModal,
  footer = true,
  cancelButtonText = 'Отмена',
  submitButtonText = 'Применить',
  submitModal,
  submitButtonDisabled,
  disableCloseOnClickOutside,
  externalFooterContent = null,
  classNames,
  headerClassNames,
  externalFooterRight,
}) => {
  return (
    <Dialog
      open={isOpen}
      className="fixed inset-0 z-[10500] overflow-y-auto bg-gray-900 bg-opacity-40 text-center"
      onClose={disableCloseOnClickOutside ? () => {} : closeModal}
    >
      <DialogBackdrop className="fixed inset-0 bg-black/30" />
      <DialogPanel
        style={{ width: `${width}px` }}
        className={`inline-block max-w-full my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md`}
      >
        {title && (
          <div
            className={cn(
              'border border border-bottom border-gray-150 px-6 py-4 flex justify-between items-center',
              {
                [headerClassNames]: headerClassNames,
              },
            )}
          >
            <DialogTitle as="h3" className="text-xl font-bold leading-6 mb-0">
              {title}
            </DialogTitle>

            <button
              type="button"
              className={`text-gray-500 
                    focus-visible:outline-none focus-visible:text-green focus-visible:ring-2 focus-visible:ring-green
                    `}
              onClick={closeModal}
            >
              <CloseIcon className="h-5 w-5" />
            </button>
          </div>
        )}

        <div
          className={cn('p-4 md:p-6', {
            [classNames]: classNames,
          })}
        >
          {children}
        </div>

        {footer && (
          <div className="border border-top border-gray-150 bg-gray-50 px-6 py-3 flex items-center gap-2 justify-end">
            {externalFooterContent && (
              <div className={externalFooterRight ? '' : 'mr-auto'}>{externalFooterContent}</div>
            )}

            {renderCancelButton && (
              <ButtonPrimary
                className={'button-primary__inverted'}
                onClick={closeModal}
                label={cancelButtonText}
              />
            )}
            <ButtonPrimary
              onClick={submitModal}
              label={submitButtonText}
              disabled={submitButtonDisabled}
            />
          </div>
        )}
      </DialogPanel>
    </Dialog>
  );
};

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  isOpen: PropTypes.bool,
  footer: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  externalFooterContent: PropTypes.any,
  renderCancelButton: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  submitButtonDisabled: PropTypes.bool,
  disableCloseOnClickOutside: PropTypes.bool,
  submitButtonText: PropTypes.string,
  closeModal: PropTypes.func,
  submitModal: PropTypes.func,
  classNames: PropTypes.string,
  headerClassNames: PropTypes.string,
  externalFooterRight: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    ),
  ]),
  align: PropTypes.oneOf(['middle', 'bottom', 'top']),
};
export default Modal;
