import { NESTED, TAB_FIRST, TAB_SECOND } from 'constants/seo';
import { CHARTS_KEYS } from 'utils/charts';

export const TABLE_TYPE = {
  ADVERTISING_RATES: 'advertising_rates',
  BRANDS: 'brands',
  CATEGORIES: 'categories',
  SELLERS: 'sellers',
  PRODUCTS: 'products',
  SUBJECTS: 'subjects',
  SALES_BY_DAY: 'sales',
  WAREHOUSES: 'warehouses',
  DYNAMIC: 'dynamic',
  PRICE_SEGMENT: 'price_segment',
  SALES_WAREHOUSES: 'sales_warehouses',
  PRODUCT_LISTING: 'product_listing',
  PRODUCT_SIMILAR: 'product_similar',
  PRODUCT_SALES: 'product_sales',
  PRODUCT_DATA: 'product_data',
  PRODUCT_SEARCH: '_product_search_',
  PRODUCT_WAREHOUSES: 'product_warehouses',
  PRODUCT_SIZES: '_product_sizes_',
  PRODUCT_COLORS: '_product_colors_',
  PRODUCT_CHANGES: '_product_changes_',
  PRODUCT_ADVERTISING: 'product_advertising',
  PRODUCT_PRICE: 'product_price',
  PRODUCT_RATING: 'product_rating',
  PRODUCT_STOCK: 'product_stock',
  PRODUCT_HOURS: 'product_hours',
  PRODUCT_SALES_INNER: 'product_sales_inner',
  [TAB_FIRST]: 'seo_first',
  [TAB_SECOND]: 'seo_second',
  [`${TAB_SECOND}${NESTED}`]: 'seo_second_nested',
  BRANDS_GROWTH: '_brands_growth__',
  CATEGORIES_GROWTH: '_categories_growth__',
  SELLERS_GROWTH: '_sellers_growth__',
  PRODUCTS_GROWTH: '_products_growth__',
  REFERRALS_REGISTRATIONS: 'referrals_registrations',
  REFERRALS_PAYMENT: 'referrals_payment',
  SEO_COMPARE_A_PRODUCTS: 'seo_compare_a_products',
  SEO_COMPARE_B_PRODUCTS: 'seo_compare_b_products',
  SEO_COMPARE_RESULTS: 'seo_compare_results',
  SEO_MONITORING_RESULTS: 'seo_monitoring_results',
  SEO_BY_PHRASE_RESULTS: 'seo_by_phrase_results',
  SEO_PRODUCT_CHECK_RESULTS: 'seo_product_check_results',
  SEO_HINTS_REQUESTS: 'seo_hints_requests',
  SEO_HINTS_REQUESTS_INNER: 'seo_hints_requests_inner',
  SEO_HINTS_WORDS: 'seo_hints_words',
  SEO_HINTS_WORDS_INNER: 'seo_hints_words_inner',
  SEO_SMART_WORDS_INNER: 'seo_smart_words_inner',
  SEO_SMART_REQUESTS: 'seo_smart_requests',
  SEO_SMART_WORDS: 'seo_smart_words',
  SEO_CLUSTERS_PHRASE_RESULTS: 'seo_clusters_phrase_results',
  SEO_CLUSTERS_ARTICLE_RESULTS: 'seo_clusters_article_results',
  SEO_SEARCH: 'seo_search_table',
  PRODUCT_DAYS: 'product_days',
  EXT_PRODUCT_SUM: 'ext_product_sum',
  COMPARISON: 'comparison',
};

export const TABLE_INNER_TYPE = {
  PRODUCTS: 'inner_products',
  IN_DAYS: 'inner_in_days',
  SALES: 'inner_sales',
  WEEKS: 'inner_weeks',
  PRODUCT: 'inner_product',
  IN_DAYS_2: 'inner_in_days_2',
  IN_DAYS_2_CHART: 'inner_in_days_2_chart',
  RATINGS: 'inner_ratings',
  RATINGS_CHART: 'inner_ratings_chart',
  PRICES_TAB: 'prices_tab',
  ORDERS_SALES: 'inner_orders_sales',
  WAREHOUSES: 'inner_warehouses',
  PRODUCT_WAREHOUSES: 'inner_product_warehouses',
  DELIVERY: 'inner_delivery',
  CARD_LIST: 'inner_card_list',
  PRODUCT_SIZES: 'inner_product_sizes',
  PROBLEMS_SUM: 'FETCH_PROBLEMS_PRODUCTS_SUM',
  PRODUCTS_SETTINGS: 'inner_products_settings',
};

export const TABLE_CELL__CUSTOM_DATA_FIELDS = {
  INDEX: 'index',
  ID: 'productId',
  TITLE: 'rowTitle',
};

export const COLUMNS = {
  CUSTOM_TITLE: TABLE_CELL__CUSTOM_DATA_FIELDS.TITLE,
  WAREHOUSES__ORDERS_QTY: 'warehouses_orders_qty',
  WAREHOUSES__ORDERS_RUB: 'warehouses_orders_rub',
  WH__STOCK_RUB: 'warehouses_stock_rub',
  WH__STOCK_QTY: 'warehouses_stock_qty',
  WAREHOUSES__STOCK_RUB: 'warehouses_money',
  WAREHOUSES__STOCK_QTY: 'warehouses_stock',
  WAREHOUSES__AVG_ORDERS_PER_DAY_RUB: 'wh_avg_orders_per_day_rub',
  WAREHOUSES__AVG_ORDERS_PER_DAY_QTY: 'wh_avg_orders_per_day_qty',
  WAREHOUSES__MISSED_RUB: 'wh_lost_orders_rub',
  WAREHOUSES__MISSED_QTY: 'wh_lost_orders_qty',
  WAREHOUSES__MISSED_PERCENT: 'wh_lost_orders_percent',
  WAREHOUSES__POTENTIAL_RUB: 'wh_potential_rub',
  WAREHOUSES__POTENTIAL_QTY: 'wh_potential_qty',
  WAREHOUSES__IN_STOCK_RUB: 'wh_avg_orders_if_in_stock_rub',
  WAREHOUSES__IN_STOCK_QTY: 'wh_avg_orders_if_in_stock_qty',
  ORDERS_QTY_BY_SIZES: CHARTS_KEYS.ORDERS_BY_SIZES_QTY,
  ORDERS_RUB_BY_SIZES: CHARTS_KEYS.ORDERS_BY_SIZES_RUB,
  AVG_ORDERS_BY_SIZES: 'sizes_avg_orders_rub',
  AVG_ORDERS_BY_SIZES_RUB: 'sizes_avg_orders_rub',
  IF_IN_STOCK_AVG_ORDERS_BY_SIZES: 'sizes_avg_orders_if_in_stock_qty',
  IF_IN_STOCK_AVG_ORDERS_BY_SIZES_RUB: 'sizes_avg_orders_if_in_stock_rub',
  MISSED_BY_SIZES: 'sizes_lost_orders_qty',
  MISSED_BY_SIZES_RUB: 'sizes_lost_orders_rub',
  MISSED_BY_SIZES_PERCENT: 'sizes_lost_orders_percent',
  POTENTIAL_BY_SIZES: 'sizes_potential_qty',
  POTENTIAL_BY_SIZES_RUB: 'sizes_potential_rub',
  EXTERNAL_ID: 'external_id',
  STOCK_BY_SIZES: CHARTS_KEYS.STOCK_BY_SIZES_QTY,
  STOCK_BY_SIZES_RUB: CHARTS_KEYS.STOCK_BY_SIZES_RUB,
  ORDERS_QTY_BY_COLORS: CHARTS_KEYS.ORDERS_BY_COLORS_QTY,
  ORDERS_RUB_BY_COLORS: CHARTS_KEYS.ORDERS_BY_COLORS_RUB,
  AVG_ORDERS_BY_COLORS: 'colors_avg_orders_qty',
  AVG_ORDERS_BY_COLORS_RUB: 'colors_avg_orders_rub',
  IF_IN_STOCK_AVG_ORDERS_BY_COLORS: 'colors_avg_orders_if_in_stock_qty',
  IF_IN_STOCK_AVG_ORDERS_BY_COLORS_RUB: 'colors_avg_orders_if_in_stock_rub',
  MISSED_BY_COLORS: 'colors_lost_orders_qty',
  MISSED_BY_COLORS_RUB: 'colors_lost_orders_rub',
  MISSED_BY_COLORS_PERCENT: 'colors_lost_orders_percent',
  POTENTIAL_BY_COLORS: 'color_potential_qty',
  POTENTIAL_BY_COLORS_RUB: 'colors_potential_rub',
  STOCK_BY_COLORS: CHARTS_KEYS.STOCK_BY_COLORS_QTY,
  STOCK_BY_COLORS_RUB: CHARTS_KEYS.STOCK_BY_COLORS_RUB,
  TITLE: 'title',
  BRAND: 'brand',
  BRAND_NAME: 'brand_name',
  BRANDS_COUNT: 'brands_count', // not exist in server respond
  SELLER_NAME: 'seller_name',
  ORDERS_RUB: 'sold_money',
  ORDERS_RUB_: 'orders_rub',
  ORDERS_QTY: 'sold_number',
  NAME: 'name',
  CATEGORIES: 'categories',
  CATEGORIES_QTY: 'categories_qty',
  PRODUCTS_SIMILAR_QTY: 'products_similar_qty',
  WAREHOUSES_QTY: 'warehouses_qty',
  TURNOVER: 'turnover',
  STOCK_QTY: 'stock',
  STOCK_QTY_REAL: 'stock_qty',
  SELLER: 'seller',
  SELLERS_COUNT: 'sellers_count', // not exist in server respond
  WAREHOUSE: 'warehouse',
  INNER_PRODUCT_ID: 'inner_product_id',
  PERIOD: 'period',
  IMAGE: 'image',
  DATE: 'date',
  PLATFORM: 'platform',
  SOLD_RUB: 'sold_by_wb_money',
  SOLD_QTY: 'sold_by_wb_number',
  PRICE: 'price',
  OLD_PRICE: 'old_price',
  MIN_PRICE: 'min_price',
  MAX_PRICE: 'max_price',
  AVG_PRICE: 'avg_price',
  LAST_PRICE: 'last_price',
  AVG_ORDER_PRICE: 'avg_sale',
  AVG_SOLD_PRICE: 'avg_sale_by_wb',
  SKU_QTY: 'inner_id_count',
  ACTIVE_SKU_QTY: 'inner_id_sold',
  ACTIVE_SKU_PERCENT: 'active_sku',
  AVG_ORDERS_1SKU_QTY: 'avg_sales_for_1_sku',
  POTENTIAL_RUB: 'potential_rub',
  POTENTIAL_QTY: 'potential_qty',
  LOST_ORDERS_RUB: 'lost_orders_rub',
  LOST_ORDERS_PERCENT: 'lost_profit_percent',
  LOST_ORDERS_QTY: 'lost_orders_qty',
  LOST_PROFIT: 'lost_profit',
  AVG_ORDERS_PER_DAY_QTY: 'avg_orders_per_day_qty',
  AVG_ORDERS_PER_DAY_RUB: 'avg_orders_per_day_rub',
  SELLERS_QTY: 'sellers_qty',
  ACTIVE_SELLERS_QTY: 'active_sellers',
  ACTIVE_SELLERS_QTY_2: 'active_sellers_qty',
  ACTIVE_SELLERS_PERCENT: 'active_sellers_percent',
  AVG_ORDERS_1SELLER_RUB: 'sales_money_for_1_seller',
  AVG_ORDERS_1SELLER_QTY: 'sales_for_1_seller',
  BRANDS_QTY: 'brands_qty',
  ACTIVE_BRANDS_QTY: 'active_brands_qty',
  ACTIVE_BRANDS_PERCENT: 'active_brands_percent',
  REVIEWS_QTY: 'reviews_qty',
  RATING: 'rating',
  RATING_AVG: 'rating_avg',
  RATING_LAST: 'last_rating',
  AVG_ORDERS_14_QTY: 'order',
  AVG_ORDERS_14_RUB: 'order_money',
  DAYS_IN_STOCK: 'days_in_stock',
  DAYS_IN_PERIOD: 'days_in_period',
  DAYS_WITH_SALES: 'days_with_sales',
  DAYS_WITH_SALES_PERCENT: 'days_with_sales_percent',
  DAYS_WITH_ORDERS: 'days_with_orders',
  AVG_ORDERS_IF_IN_STOCK_QTY: 'avg_orders_if_in_stock_qty',
  AVG_ORDERS_IF_IN_STOCK_RUB: 'avg_orders_if_in_stock_rub',
  LISTING: 'listing',
  COLOR: 'color',
  ID: 'id',
  MONTH_ORDERS_RUB: 'orders_rub_30d',
  MONTH_ORDERS_QTY: 'orders_qty_30d',
  WEEK_ORDERS_RUB: 'orders_rub_7d',
  WEEK_ORDERS_QTY: 'orders_qty_7d',
  AVG_ORDERS_1SKU_RUB: 'avg_orders_1sku_rub',
  AVG_ORDER_IN_STOCK: 'avg_order_in_stock',
  AVG_ORDER_RUB: 'avg_order_money',
  AVG_ORDER_RUB_IN_STOCK: 'avg_order_money_in_stock',
  AVG_ORDER: 'avg_order',
  REVIEWS_COUNT: 'reviews_count',
  REVIEWS_COUNT_LAST: 'last_reviews_count',
  CATEGORY: 'category',
  SEARCH_PHRASE: 'search_phrase',
  PICTURES_QTY: 'pictures_qty',
  SPECIFICATION: 'specification',
  DESCRIPTION: 'description',
  HAS_360: '360',
  VIDEO: 'video',
  TYPE_OF_CHANGE: 'type_of_change',
  IT_WAS: 'it_was',
  BECAME: 'became',
  ADV_TYPE: 'adv_type',
  ADV_MEANING: 'adv_meaning',
  PARSING_TIME: 'parsing_time',
  TIME: 'time',
  POSITION: 'position',
  CPM: 'cpm',
  VERTICAL_HEADER: 'vertical_header',
  SKU_QTY_: 'sku_qty',
  AVG_POSITION_BY_KEYWORD: 'avg_position_by_keyword',
  AVG_POSITION_BY_DAY: 'avg_position_by_day',
  VISIBILITY: 'visibility',
  PICTURES: 'pictures_qty',
  LINK: 'link',
  PHRASE_NAME: 'phrase_name',
  COUNT_PRODUCTS: 'count_products',
  PRODUCTS: 'products',
  FREQUENCY_OZON: 'frequency_ozon',
  FREQUENCY_YANDEX: 'frequency_yandex',
  WORD: 'word',
  FORMS: 'forms',
  COUNT: 'count',
  SIMILAR_PHRASES: 'similar_phrases',
  SIMILAR_NAME: 'similar_name',
  SELLERS_WITH_ORDERS: 'sellers_with_orders_qty',
  SELLERS_WITH_ORDERS_: 'sellers_with_orders',
  SELLER_ABG_ORDERS: 'avg_orders_1seller_rub',
  BRANDS_WITH_ORDERS: 'brands_with_orders_qty',
  SKU_WITH_ORDERS: 'sku_with_orders_qty',
  ORDERS_QTY_: 'orders_qty',
  AVERAGE_RUB: 'average_rub',
  SELLERS_WITH_PRODUCT: 'sellers_with_products',
  REVIEW: 'review',
  BRANDS_WITH_ORDER: 'brands_with_orders',
  BRANDS_WITH_PRODUCTS: 'brands_with_products',
  SELLERS_ORDERS: 'seller_orders',
  PRODUCTS_WITH_ORDERS: 'product_with_orders',
  SOLD_MONEY_GROWTH: 'sold_money_growth',
  SOLD_NUMBER_GROWTH: 'sold_number_growth',
  PRODUCTS_GROWTH: 'products_growth',
  SELLERS_GROWTH: 'sellers_growth',
  SOLD_MONEY_BASIS: 'sold_money_basis',
  SOLD_NUMBER_BASIS: 'sold_number_basis',
  PRODUCTS_NUMBER: 'products_number',
  PRODUCTS_BASIS: 'products_basis',
  SELLERS_NUMBER: 'sellers_number',
  SELLERS_BASIS: 'sellers_basis',
  FREQUENCY_WB: 'frequency_wb',
  BARCODE: 'barcode',
  SRID: 'srid',
  SHK_ID: 'shk_id',
  INCOME_ID: 'income_id',
  ORDER_DT: 'order_dt',
  SALE_DT: 'sale_dt',
  RETURN_DT: 'return_dt',
  CANCEL_DT: 'cancel_dt',
  OBR_LOG_DT: 'obr_log_dt',
  NDS_PPVZ_KVW_PER_BASE: 'nds_ppvz_kvw_prc_base',
  SUM_ORDERS_INNER: 'sum_orders_by_period_rub',
  COUNT_ORDERS_INNER: 'count_orders_by_period',
  SALES_SUM: 'sales_rub',
  SALES_QTY: 'sales_qty',
  SALES_SPEED: 'sales_speed',
  SUM_SALES_INNER: 'sum_sales',
  COUNT_SALES_INNER: 'count_sales',
  SUM_RETURN_INNER: 'sum_return_orders_rub',
  SUBJECT: 'subject',
  COUNT_RETURN_INNER: 'count_return_orders',
  PRIME_COST: 'prime_cost',
  PAYOFF_YESTERDAY: 'payoff_yesterday',
  PAYOFF_7: 'payoff_7days',
  PAYOFF_PERIOD: 'payoff_period',
  ORDERS_YESTERDAY_RUB: 'orders_yesterday_rub',
  SALES_YESTERDAY_RUB: 'sales_yesterday_rub',
  ORDERS_7D_RUB: 'orders_7d_rub',
  SALES_7D_RUB: 'sales_7d_rub',
  ORDERS_PERIOD_RUB: 'orders_rub',
  SALES_PERIOD_RUB: 'sales_rub',
  RETURNS_YESTERDAY_RUB: 'returns_yesterday_rub',
  RETURNS_7D_RUB: 'returns_7d_rub',
  RETURNS_PERIOD_RUB: 'returns_period_rub',
  ORDERS_YESTERDAY_QTY: 'orders_yesterday_qty',
  SALES_YESTERDAY_QTY: 'sales_yesterday_qty',
  ORDERS_7D_QTY: 'orders_7d_qty',
  SALES_7D_QTY: 'sales_7d_qty',
  ORDERS_PERIOD_QTY: 'orders_qty',
  SALES_PERIOD_QTY: 'sales_qty',
  RETURNS_YESTERDAY_QTY: 'returns_yesterday_qty',
  RETURNS_7D_QTY: 'returns_7d_qty',
  RETURNS_PERIOD_QTY: 'returns_qty',
  LOST_PERCENT_ORDERS: 'lost_orders_percent',
  LOST_REVENUE_ORDERS: 'lost_revenue_percent',
  LOST_QTY_ORDERS: 'lost_orders_qty',
  LOST_RUB_ORDERS: 'lost_orders_rub',
  LOST_REVENUE: 'lost_revenue',
  POTENCIAL_REVENUE: 'potencial_revenue',
  PAYOFF: 'payoff',
  MARGIN: 'margin',
  PROFITABILITY: 'profitability',
  COMMISSION: 'sum_ppvz_vw_ppvz_vw_nds',
  LOGISTIC: 'last_ppvz_reward',
  TURN_AROUND_ORDERS: 'turn_around_orders',
  TURN_AROUND_SALES: 'turn_around_sales',
  TOTAL_PRICE: 'total_price',
  SPP: 'ppvz_spp_prc',
  SPP_RUB: 'spp_rub',
  PRICE_WITH_SPP: 'price_with_spp',
  DISCOUNT: 'discount',
  MARGINALITY: 'marginality',
  RETAIL_PRICE: 'retail_price_withdisc_rub',
  PRICE_WITH_DISC: 'price_with_disc',
  AVG_ORDERS_QTY: 'avg_orders_qty',
  AVG_STOCK_QTY: 'avg_orders_qty_if_in_stock',
  AVG_ORDERS_RUB: 'avg_orders_rub',
  AVG_STOCK_RUB: 'avg_orders_rub_if_in_stock',
  DAYS_ON_AVAILABLE: 'days_on_available',
  DAYS_WIDTH_ORDERS_PERCENT: 'days_with_orders_percent',
  SPP_PERCENT: 'spp_percent',
  QUANTITY: 'quantity',
  QUANTITY_FULL: 'quantity_full',
  QUANTITY_IN_WAY: 'quantity_in_way',
  QUANTITY_PRIME: 'quantity_prime_cost',
  QUANTITY_FULL_PRIME: 'quantity_full_prime_cost',
  QUANTITY_IN_WAY_PRIME: 'quantity_in_way_prime_cost',
  QUANTITY_RETAIL: 'quantity_retail_cost',
  QUANTITY_FULL_RETAIL: 'quantity_full_retail_cost',
  QUANTITY_IN_WAY_RETAIL: 'quantity_in_way_retail_cost',
  QUANTITY_FULL_RRC: 'quantity_full_rrc',
  QUANTITY_FULL_PC: 'quantity_full_pc',
  IN_WAY_TO_CLIENT: 'in_way_to_client',
  IN_WAY_TO_CLIENT_RCC: 'in_way_to_client_rrc',
  IN_WAY_TO_CLIENT_PC: 'in_way_to_client_pc',
  IN_WAY_FROM_CLIENT: 'in_way_from_client',
  IN_WAY_FROM_CLIENT_RCC: 'in_way_from_client_rrc',
  IN_WAY_FROM_CLIENT_PR: 'in_way_from_client_pr',
  QUANTITY_BY_API: 'quantity_by_api',
  QUANTITY_BY_API_RPC: 'quantity_by_api_rpc',
  QUANTITY_BY_API_PC: 'quantity_by_api_pc',
  OUT_OF_STOCK: 'out_of_stock',
  OUT_OF_STOCK_DATE: 'out_of_stock_dt',
  BUYOUT: 'buyout',
  BUYOUT_WITH_REFUND: 'buyout_with_refund',
  SUPPLIER: 'supplier',
  CALC_QUANTITY: 'calc_quantity',
  CLUSTER_NAME: 'cluster_name',
  DEMAND_INDEX: 'demand_index',
  GENERAL_GRAPH: 'general_graph',
  CATEGORY_CHARTS: 'category_charts',
};

export const COLUMNS_IDS = {
  [TABLE_TYPE.BRANDS]: 'brand_id',
  [TABLE_TYPE.CATEGORIES]: 'category_id',
  [TABLE_TYPE.SELLERS]: 'seller_id',
  [TABLE_TYPE.SUBJECTS]: 'subject_id',
  [TABLE_TYPE.PRODUCTS]: 'id',
  [TABLE_TYPE.PRODUCT_SIMILAR]: 'id',
};

export const FIELD_IDS = {
  brand: 'brand_id',
  category: 'category_id',
  seller: 'seller_id',
};

export const CELL_RENDER = {
  CURRENCY: 'CURRENCY',
  CURRENCY_WITH_PERCENT: 'CURRENCY_WITH_PERCENT',
  AMOUNT_WITH_PERCENT: 'AMOUNT_WITH_PERCENT',
  CATEGORY: 'CATEGORY',
};

export const TABLE_EXPORT_BACKEND = [
  TABLE_TYPE.CATEGORIES,
  TABLE_TYPE.SUBJECTS,
  TABLE_TYPE.SELLERS,
  TABLE_TYPE.BRANDS,
  TABLE_TYPE.PRODUCTS,
  TABLE_TYPE.DYNAMIC,
  TABLE_TYPE.WAREHOUSES,
  TABLE_TYPE.SALES_BY_DAY,
];

export const CELL_PERIOD_PREFIX = 'period_';

export const CELL_SELECT_VISIBILITY = '{{select-visibility}}';
export const CELL_SELECT_AVG_POSITION = '{{select-avg-position}}';

export const COLUMN_HIDE_FILTER_ON_EMPTY_SORTING = [
  TABLE_TYPE.BRANDS,
  TABLE_TYPE.SELLERS,
  TABLE_TYPE.SUBJECTS,
  TABLE_TYPE.PRODUCTS,
  TABLE_TYPE.BRANDS_GROWTH,
  TABLE_TYPE.SELLERS_GROWTH,
  TABLE_TYPE.PRODUCTS_GROWTH,
];

export const TABLE_SORTED_ON_BACK = [
  TABLE_TYPE.PRODUCTS,
  TABLE_TYPE.SELLERS,
  TABLE_TYPE.SUBJECTS,
  TABLE_TYPE.BRANDS,
  TABLE_TYPE.PRODUCTS_GROWTH,
  TABLE_TYPE.COMPARISON,
  TABLE_INNER_TYPE.PRODUCTS,
  TABLE_INNER_TYPE.IN_DAYS,
  TABLE_INNER_TYPE.SALES,
  TABLE_INNER_TYPE.WEEKS,
  //TABLE_INNER_TYPE.IN_DAYS_2,
  TABLE_INNER_TYPE.IN_DAYS_2_CHART,
  //TABLE_INNER_TYPE.RATINGS,
  TABLE_INNER_TYPE.RATINGS_CHART,
  TABLE_INNER_TYPE.PRICES_TAB,
  TABLE_INNER_TYPE.ORDERS_SALES,
  TABLE_INNER_TYPE.DELIVERY,
  // TABLE_INNER_TYPE.WAREHOUSES,
  // TABLE_INNER_TYPE.PRODUCT_WAREHOUSES,
];
export const TABLE_RESTRICTED_ON_BACK = [
  //TABLE_TYPE.CATEGORIES,
  TABLE_TYPE.PRODUCTS,
  TABLE_TYPE.BRANDS,
  TABLE_TYPE.SELLERS,
  TABLE_TYPE.SUBJECTS,
  //TABLE_TYPE.CATEGORIES_GROWTH,
  TABLE_TYPE.PRODUCTS_GROWTH,
  TABLE_TYPE.PRODUCTS_GROWTH,
  TABLE_INNER_TYPE.PRODUCTS,
  TABLE_INNER_TYPE.IN_DAYS,
  TABLE_INNER_TYPE.SALES,
  TABLE_INNER_TYPE.WEEKS,
  // TABLE_INNER_TYPE.IN_DAYS_2,
  // TABLE_INNER_TYPE.RATINGS,
  TABLE_INNER_TYPE.ORDERS_SALES,
  TABLE_INNER_TYPE.PRICES_TAB,
  TABLE_INNER_TYPE.DELIVERY,
  // TABLE_INNER_TYPE.WAREHOUSES,
  // TABLE_INNER_TYPE.PRODUCT_WAREHOUSES,
];
export const TABLE_RESTRICTED_ON_BACK_WITH_FILTER = [
  TABLE_TYPE.PRODUCTS,
  TABLE_TYPE.BRANDS,
  TABLE_TYPE.SELLERS,
  TABLE_TYPE.SUBJECTS,
  TABLE_TYPE.PRODUCTS_GROWTH,
];

export const TABLE_TEXT_FILTERED_ON_BACK = [
  //TABLE_TYPE.CATEGORIES,
  TABLE_TYPE.PRODUCTS,
  TABLE_TYPE.BRANDS,
  TABLE_TYPE.SELLERS,
  TABLE_TYPE.SUBJECTS,
  //TABLE_TYPE.CATEGORIES_GROWTH,
  TABLE_TYPE.BRANDS_GROWTH,
  TABLE_TYPE.SELLERS_GROWTH,
  TABLE_INNER_TYPE.SALES,
];

export const TABLE_COLUMNS_TEXT_FILTERED_ON_BACK = [
  COLUMNS.TITLE,
  COLUMNS.NAME,
  COLUMNS.BRAND,
  COLUMNS.SELLER,
  COLUMNS.EXTERNAL_ID,
  COLUMNS.BARCODE,
  COLUMNS.SRID,
  COLUMNS.SHK_ID,
  COLUMNS.INCOME_ID,
];

export const TABLE_COLUMNS_TEXT_DATES_FILTERED_ON_BACK = [
  COLUMNS.ORDER_DT,
  COLUMNS.SALE_DT,
  COLUMNS.RETURN_DT,
  COLUMNS.CANCEL_DT,
  COLUMNS.OBR_LOG_DT,
];

export const COLUMNS_PRODUCT = {
  ORDERS_RUB: 'orders_rub',
  ORDERS_QTY: 'orders_qty',
  STOCK_RUB: 'money',
  STOCK_QTY: 'stock',
  STOCK_BEFORE_OUT: 'days_before_stock_out',
  AVG_ORDERS_PER_DAY_RUB: 'avg_orders_per_day_rub',
  AVG_ORDERS_PER_DAY_QTY: 'avg_orders_per_day_qty',
  IN_STOCK_RUB: 'avg_orders_if_in_stock_rub',
  IN_STOCK_QTY: 'avg_orders_if_in_stock_qty',
  MISSED_RUB: 'lost_orders_rub',
  MISSED_QTY: 'lost_orders_qty',
  MISSED_PERCENT: 'lost_orders_percent',
  POTENTIAL_RUB: 'potential_rub',
  POTENTIAL_QTY: 'potential_qty',
};

export const PRODUCT_SORT = {
  [TABLE_TYPE.PRODUCT_SEARCH]: COLUMNS.FREQUENCY_WB,
};
